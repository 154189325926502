import $ from 'jquery';
import { DateTime } from 'luxon'

/**
 * Gets a given icon by its name from SVG sprite sheets
 * @param {string} icon SVG name
 * @returns {*|jQuery|HTMLElement}
 */
export function getSVGForIcon(icon) {
    if (typeof icon === 'string') {
        icon = [icon];
    }

    const $wrapper = $('<span>');

    $wrapper.append(...icon.map(function (icon) {
        const $svgIcon = $(`symbol#${icon}`);
        const svgIconViewbox = $svgIcon[0].getAttribute('viewBox'),
            svgIconContent = $svgIcon.children().clone();

        return $(`<svg viewBox="${svgIconViewbox}">`)
            .addClass('activity-icon')
            .append(svgIconContent);
    }))

    return $wrapper;
}

export function modalForm(modal_id) {
    const $modal = app.DOM.content.find(`#${modal_id}`);

    const $btnSubmit = $modal.find('button[data-action="ok"]');

    // prevent form submission
    $modal.find('form').on('submit', e => {
        e.preventDefault();

        $btnSubmit.trigger('click');
    });
}

/**
 * Open generic modal with js
 * @param {string} title Modal header title defaults to '' if null passed
 * @param {string} content Body content for the modal can be either text or a string of HTML
 * @todo Convert this to the new modal system when possible
 */
export function modalGeneric(title, content) {
    let $modal = $('#modal-generic');
    $modal.find('#modal-generic-title span').text(title);
    $modal.find('#modal-generic-content').html(content);

    MicroModal.show('modal-generic');
}

/**
 * Gets a given resources url from the manifest
 * @param {string} path the resources path without resources/ e.g. "js-esm/shared/common.js"
 * @returns {string} Resolved resource URL
 */
export function manifest(path) {
    let target = path.replace(/^[\/\.]+/, '');
    let fragment = '';
    if (target.includes('#')) {
        [target, fragment] = target.split('#');
    }
    if(app.OPTIONS.manifest[target]) {
        return app.OPTIONS.manifest[target] + (fragment !== '' ? '#' + fragment : '');
    }

    if (/^js\//.test(target)) {
        return '/dist/' + target;
    }

    return path;
}

export function displayErrors(res, modalToClose, err, returnErrorMessage) {
    
    const defaultMessage = 'There was an error trying to complete your request, please try again';

    if( modalToClose && typeof(MicroModal) !== undefined ) {
        MicroModal.close(modalToClose);
    }

    // check returning xhr request needing rebase
    if(res.hasOwnProperty('responseJSON')) {
        res = res.responseJSON;
    }

    // DATA: ERROR
    if(res && $.isArray(res.errors) && res.errors.length > 0) {

        let msg = '';
        const eol = ( typeof(MicroModal) === 'undefined' || returnErrorMessage ) ? '\n' : '<br>';

        $.each(res.errors, (k,v) => {

            // highlight it in dom
            if(v.key && app.OPTIONS.form) {
                var $el = app.DOM.form_input.filter(`#${v.key}`).closest('p');

                if( $el.length ){
                    // error container
                    $el.addClass('highlight');

                    // error tab
                    app.FORM.getTabKey($el.closest('.tab'), true).addClass('error');
                }
            }

            // concat message
            msg += `- ${v.msg}${eol}`;
        });

        // default browser alert
        if( typeof(MicroModal) === 'undefined' || returnErrorMessage) {
            alert(msg);
            return;
        }

        // modal
        if(app.OPTIONS.form) {
            app.FORM.msg_modal(msg, 'There were errors:');
        } else {
            modalGeneric('There was an error', msg);
        }

        return;
    }

    // API OR CONNECTION ERROR
    if( typeof(MicroModal) === 'undefined' ){
        alert(defaultMessage);
    } else {
        modalGeneric('Attention', defaultMessage);
    }

    console.warn(res);

    if(err) {
        console.log(err);
    }
}

/**
 * Get key or value from URI
 *
 * /admin/cos/false/veh:123
 * @param {string|number} param
 * @param {boolean} getValue
 */
export function getFilterParam(param, getValue = true) {
    param = app.URI[param];

    if( !param || param.indexOf(':') <= 0 ) {
        return false;
    }

    // get first & last params
    param = param.split(':');

    if( param.length > 2 ) {
        const param_start = param[0];

        param.shift();

        const param_end = decodeURI(param.join(':'));

        param = [
            param_start,
            param_end
        ];
    }

    if( getValue ) {
        return param.pop();
    }

    return param[0];
}

export function generateGuid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for(let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getFilterKey(param, uriParam) {
    if( uriParam === undefined ) {
        uriParam = true;
    }

    return app.get_filter_param(param, false, uriParam);
}

export function getFilterValue(param, uriParam) {
    if( uriParam === undefined ) {
        uriParam = true;
    }

    return app.get_filter_param(param, true, uriParam);
}

export function autocompleteOpts() {
    return `autocomplete="${generateGuid(12)}" donotautocomplete aria-autocomplete="none" readonly`;
}

// For both of these, you need to note that only moment supports ordinal numbers (i.e., 1st Jan vs 1 Jan)
export const MOMENT_FORMATS = {
    GENERAL_DATE_FORMAT:                      'Do MMMM YYYY',            // PHP version of 'jS F Y';
    GENERAL_DATETIME_FORMAT:                  'Do MMMM YYYY, hh:mma',    // PHP version of 'jS F Y, H:ia';
    GENERAL_DATETIME_FORMAT_WITH_SECONDS:     'Do MMMM YYYY, hh:mm:ssa', // PHP version of 'jS F Y, H:i:sa';
    GENERAL_TIME_FORMAT:                      'hh:mma',                  // PHP version of 'h:ia';
    GENERAL_TIME_FORMAT_WITH_SECONDS:         'hh:mm:ssa',               // PHP version of 'h:i:sa';
};

// Luxon wraps Intl.DateTimeFormat, which doesn't have a positional parameter for ordinals or numbers with the ordinal part
export const LUXON_FORMATS = {
    GENERAL_DATE_FORMAT:                      DateTime.DATE_MED,
    GENERAL_DATETIME_FORMAT:                  {...DateTime.DATE_MED, ...DateTime.TIME_SIMPLE},
    GENERAL_DATETIME_FORMAT_WITH_SECONDS:     {...DateTime.DATE_MED, ...DateTime.TIME_WITH_SECONDS},
    GENERAL_TIME_FORMAT:                      DateTime.TIME_SIMPLE,
    GENERAL_TIME_FORMAT_WITH_SECONDS:         DateTime.TIME_WITH_SECONDS,
};

export function tableFilter(search, extraData) {
    const defaultData = {
            filter_inverse: false, // when true show the ones that don`t match the keyword search
            enable_space: false, // when true check each word in the td separately
            table: "", // this is expected to by the jquery object of tbody of the table we want to filter
            filter_by_inputs: false, // when true where possible use the value of input field inside the td
            excluded_row_classes: "", // when classes like ".a .b .c" we will exclude these rows from keyword filtering
            // if a function this will exclude rows from the keyword filtering
            // we pass in the jquery object of rows as the only parameter
            ignore_rows_callback: undefined,
            // if handle_show_hide_callback is a function this lets us handle the logic for showing/hiding the rows
            // but not the comparing of the data to see what should be shown.
            // we pass the jquery row or rows and a boolean for if it/they should be shown or not
            handle_show_hide_callback: undefined
        },
        data = $.extend(defaultData, extraData),
        lowerCaseSearch = search.trim().toLowerCase();

    let rows = $(data.table).find("tr:not(:has('th')):not(.th)").not('.paging');

    // we have additional classes to check for to ensure we do not show/hide specific rows
    if (data.excluded_row_classes !== "") {
        // when we have multiple classes we split them up and add them as separate nots
        if (data.excluded_row_classes.indexOf(" ") > 0) {
            $.each(data.excluded_row_classes.split(" "), function () {
                rows = rows.not(this);
            });
        } else {
            rows = rows.not(data.excluded_row_classes);
        }
    }

    // we were provided with a callback method to call to ensure we do not show/hide the wrong rows
    if (typeof data.ignore_rows_callback === "function") {
        rows = data.ignore_rows_callback(rows);
    }

    // cleared search show all the rows again
    if (lowerCaseSearch === "") {
        // need to handle show/hide uniquely
        if (typeof data.handle_show_hide_callback === "function") {
            data.handle_show_hide_callback(rows, true);
        } else {
            rows.show();
        }
        return;
    }

    // loop through table rows
    $.each(rows, function(){
        let show = data.filter_inverse,
            row = $(this),
            tableCells = row.find("td.keyword-filterable");

        $.each(tableCells, function(){
            let cell = $(this),
                comparisonText;

            // find input fields to compare the search to. If there is not one we use the cell text
            if (data.filter_by_inputs) {
                const elements = cell.find(".keyword-filterable");
                if (elements.length > 0) {
                    $.each(elements, function(index, element) {
                        if (index > 0) {
                            comparisonText += " ";
                        }

                        switch (element.tagName) {
                            case "INPUT":
                                comparisonText += $.trim(element.value).toLowerCase();
                                break;
                            case "TEXTAREA":
                                comparisonText += $.trim(element.html.replace("\n", " ")).toLowerCase();
                                break;
                            case "SELECT":
                                comparisonText += $(element).find("option:not([value='']):selected").map(function () {
                                    return $.trim($(this).text().replaceAll("\n", " ")).toLowerCase();
                                }).get().join(', ');
                                break;
                            default:
                                comparisonText += $.trim(cell.text()).toLowerCase();
                                break;
                        }
                    });

                } else {
                    comparisonText = $.trim(cell.text()).toLowerCase();
                }
            } else {
                // compare to the cell text
                comparisonText = $.trim(cell.text()).toLowerCase();
            }

            // handle text with spaces to compare against each word
            if (data.enable_space) {
                $.each(comparisonText.split(" "), function(){
                    // matched one word show the row
                    if (this.indexOf(lowerCaseSearch) >= 0) {
                        show = !data.filter_inverse;
                    }
                })

            } else {
                // matched the string show the row
                if (comparisonText.indexOf(lowerCaseSearch) >= 0) {
                    show = !data.filter_inverse;
                }
            }
        });

        // need to do some extra checks before we can show or hide the row
        if (typeof data.handle_show_hide_callback === "function") {
            data.handle_show_hide_callback(row, show);
        } else {
            show ? row.show() : row.hide();
        }
    });
}

export function preventEnterSubmittingForm(e) {
    // prevent saving the form when pressing enter
    if (parseInt(e.keyCode) === 13) {
        e.preventDefault();
        return false;
    }
}