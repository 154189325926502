import {ModalBase} from "../modal_base";
import {displayErrors} from "../../shared/common";
import {AjaxSync} from "../../shared/ajax_utilities";

export class ListCopyModal extends ModalBase {
    constructor() {
        super('list_copy_modal');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const url = $modalTriggerBtn.attr('href');

            const $name = this.MODAL_CONTAINER_EL.find('#name');
            const $opr = this.MODAL_CONTAINER_EL.find('#operator');
            const $copy_que = this.MODAL_CONTAINER_EL.find('#copy_que_yes');
            const errors = checkErrors($modalTriggerBtn, [$name, $opr]);
            let data = {
                text: $modalTriggerBtn.attr('data-text')
            };

            // vehicle type (reports only)
            if( app.URI[2] === 'rep_che' ) {
                data.vehicle_type_id_from = $modalTriggerBtn.attr('data-vehicle-type-id');
                data.vehicle_type_id_to = this.MODAL_CONTAINER_EL.find('#modal_vehicle_type_id').val();
            }

            // re-open modal
            if( errors.length > 0 ) {
                return false;
            }

            // copy questions
            data.copy_que = ( $copy_que.is(':checked') ) ? '1'  : '0';
            data.name = $name.val();
            data.operator_id = $opr.val();

            // SEND AJAX
            AjaxSync({
                type: 'POST',
                data: data,
                url: url
            }, {
                done: (res) => {
                    if( !res.status || res.status === 'error' ) {
                        displayErrors(res);
                    } else {
                        window.location.reload();
                    }
                },
                error: (request, error) => {
                    displayErrors(request, error);
                }
            });
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}